export const getMixedData = (resultRec, resultMed) =>
    resultRec.map((product, index) => {
        let medProduct = {};
        if (resultMed[index] && product.SKU === resultMed[index].SKU) {
            medProduct = resultMed[index];
        } else {
            medProduct = resultMed.find((datum) => datum.SKU === product.SKU);
        }
        if (medProduct === undefined) {
            medProduct =
                resultMed.find((datum) => datum.ProductName === product.ProductName) || {};
        }
        return {
            ...product,
            PriceMed: medProduct.Price,
            PriceGramMed: medProduct.PriceGram,
            PriceEighthMed: medProduct.PriceEighth,
            PriceQuarterMed: medProduct.PriceQuarter,
            PriceHalfOunceMed: medProduct.PriceHalfOunce,
            PriceOunceMed: medProduct.PriceOunce,
        };
    });

export const areaCategories = [
    "Flower",
    "Edible",
    "Joint",
    "Vape",
    "Extract",
    "Tincture",
    "Topical",
    "Merchandise",
    "Ad",
    "Content",
    "CBD"
];

export const thcDecimals = ["Default", "0", "1", "2"]

export const menuCategories = [
    "Flower",
    "Edible",
    "Joint",
    "Vape",
    "Extract",
    "Tincture",
    "Topical",
    "Merchandise",
    "CBD"
];

export const dominances = ["Sativa", "Sat/Hy", "Hybrid", "Ind/Hy", "Indica", "Cbd"];

export const sortOptions = [
    "Dominance",
    "Name",
    "Brand",
    "Type",
    "Thc Ascending",
    "Thc Descending",
    "Weight Ascending",
    "Weight Descending",
    "Price Ascending",
    "Price Descending",
];

export const pricePoints = [
    "Price",
    "PriceGram",
    "PriceEighth",
    "PriceQuarter",
    "PriceHalfOunce",
    "PriceOunce",
    "PriceTwoGram",
];

export const dominanceSort = {
    sativa: 100,
    "sat/hy": 200,
    "sativa-hybrid": 200,
    hybrid: 300,
    "ind/hy": 400,
    "indica-hybrid": 400,
    indica: 500,
    cbd: 600,
    null: 700,
    "none": 700,
};

export const sortFunc = (a, b, sorting) => {
    let sorts = [
        [0, 0],
        [0, 0],
        [0, 0],
    ];
    sorting.forEach((element, index) => {
        if (element === "Dominance") {
            sorts[index][0] =
              dominanceSort[a.Dominance ? a.Dominance.toLowerCase() : "none"];
            sorts[index][1] =
              dominanceSort[b.Dominance ? b.Dominance.toLowerCase() : "none"];
        } else if (element === "Name") {
            sorts[index][0] = a.ProductName;
            sorts[index][1] = b.ProductName;
        } else if (element === "Brand") {
            sorts[index][0] = a.Brand;
            sorts[index][1] = b.Brand;
        } else if (element === "Type") {
            sorts[index][0] = a.Type;
            sorts[index][1] = b.Type;
        } else if (element === "Weight Ascending") {
            sorts[index][0] = a.Weight;
            sorts[index][1] = b.Weight;
        } else if (element === "Weight Descending") {
            sorts[index][0] = b.Weight;
            sorts[index][1] = a.Weight;
        } else if (element === "Price Ascending") {
            if (a.PriceOunce && b.PriceOunce) {
                sorts[index][0] = a.PriceOunce;
                sorts[index][1] = b.PriceOunce;
            } else if (a.PriceHalfOunce && b.PriceHalfOunce) {
                sorts[index][0] = a.PriceHalfOunce;
                sorts[index][1] = b.PriceHalfOunce;
            } else if (a.PriceQuarter && b.PriceQuarter) {
                sorts[index][0] = a.PriceQuarter;
                sorts[index][1] = b.PriceQuarter;
            } else if (a.PriceEighth && b.PriceEighth) {
                sorts[index][0] = a.PriceEighth;
                sorts[index][1] = b.PriceEighth;
            } else if (a.PriceGram && b.PriceGram) {
                sorts[index][0] = a.PriceGram;
                sorts[index][1] = b.PriceGram;
            } else {
                sorts[index][0] = a.Price;
                sorts[index][1] = b.Price;
            }
        } else if (element === "Price Descending") {
            if (a.PriceOunce && b.PriceOunce) {
                sorts[index][0] = b.PriceOunce;
                sorts[index][1] = a.PriceOunce;
            } else if (a.PriceHalfOunce && b.PriceHalfOunce) {
                sorts[index][0] = b.PriceHalfOunce;
                sorts[index][1] = a.PriceHalfOunce;
            } else if (a.PriceQuarter && b.PriceQuarter) {
                sorts[index][0] = b.PriceQuarter;
                sorts[index][1] = a.PriceQuarter;
            } else if (a.PriceEighth && b.PriceEighth) {
                sorts[index][0] = b.PriceEighth;
                sorts[index][1] = a.PriceEighth;
            } else if (a.PriceGram && b.PriceGram) {
                sorts[index][0] = b.PriceGram;
                sorts[index][1] = a.PriceGram;
            } else {
                sorts[index][0] = b.Price;
                sorts[index][1] = a.Price;
            }
        } else if (element === "Thc Ascending") {
            sorts[index][0] = a.Thc;
            sorts[index][1] = b.Thc;
        } else if (element === "Thc Descending") {
            sorts[index][0] = b.Thc;
            sorts[index][1] = a.Thc;
        }
    });

    if (sorts[0][0] < sorts[0][1]) return -1;
    if (sorts[0][0] > sorts[0][1]) return 1;
    if (sorts[1][0] < sorts[1][1]) return -1;
    if (sorts[1][0] > sorts[1][1]) return 1;
    if (sorts[2][0] < sorts[2][1]) return -1;
    if (sorts[2][0] > sorts[2][1]) return 1;

    // console.log(sorts);
    return 0;
};

export const filterFunc = (datum, priceFilter, filterRestriction, taxData = {}) => {

    return filterRestriction.reduce((acc, cur) => {
        let price = datum[cur];
        if (taxData.applyTaxToScreens && taxData.taxRate > 0) {
            price = (price * (taxData.taxRate / 100 + 1)).toFixed(2);
        }

        if (taxData.applyRoundingToScreens) {
            if (taxData.roundingValue === "Tenths") {
                price = Math.round((parseFloat(price) + Number.EPSILON) * 10) / 10;
            } else if (taxData.roundingValue === "Whole") {
                price = Math.round((parseFloat(price) + Number.EPSILON) * 1) / 1;
            }
        }

        if (priceFilter.filterOption === "Less Than") {
            acc = price !== null && price < priceFilter.filterNumbers[0] ? true : acc;
        } else if (priceFilter.filterOption === "Equal To") {
            acc = price !== null && price == priceFilter.filterNumbers[0] ? true : acc;
        } else if (priceFilter.filterOption === "Greater Than") {
            acc = price !== null && price > priceFilter.filterNumbers[0] ? true : acc;
        } else if (priceFilter.filterOption === "Between") {
            const larger =
                priceFilter.filterNumbers[0] > priceFilter.filterNumbers[1]
                    ? priceFilter.filterNumbers[0]
                    : priceFilter.filterNumbers[1];
            const smaller =
                priceFilter.filterNumbers[0] < priceFilter.filterNumbers[1]
                    ? priceFilter.filterNumbers[0]
                    : priceFilter.filterNumbers[1];
            acc = price !== null && price < larger && price > smaller ? true : acc;
        }
        return acc;
    }, false);
};

export const makeFirstLetterUpperCase = (string) => string.charAt(0).toUpperCase() + string.slice(1);
export const getApolloData = (data) => {
  return data.map((el) => {
    return Object.keys(el).reduce(
      (obj, key) => {
          return ({ ...obj, [makeFirstLetterUpperCase(key)]: el[key] })
      },
      {}
    );
  });
};