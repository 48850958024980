import React, { useState, useEffect, useRef } from "react";

const ScheduledMediaZone = ({
  mediaItems,
  defaultFitStyle = "cover",
  customStyles = {},
  fallbackColor = "#000000",
  transitionDuration = 1000,
  children,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadedUrls, setLoadedUrls] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);

  // Add debug state to track loading progress
  const [debugLoadingState, setDebugLoadingState] = useState({
    totalItems: mediaItems.length,
    loadedItems: 0,
    lastLoadedItem: null,
    errors: [],
  });

  const currentMediaRef = useRef(null);
  const nextMediaRef = useRef(null);
  const transitionTimer = useRef(null);
  const durationTimer = useRef(null);

  const currentItem = mediaItems[currentIndex];
  const nextItem = mediaItems[(currentIndex + 1) % mediaItems.length];

  // Enhanced preload function with debugging
  const preloadItem = (item) => {
    console.log(`🔄 Starting preload for ${item.type}: ${item.url}`);

    return new Promise((resolve) => {
      if (item.type === "video") {
        // Create temporary video element to actually preload video
        const videoElement = document.createElement("video");

        videoElement.onloadeddata = () => {
          console.log(`✅ Video preloaded successfully: ${item.url}`);
          setLoadedUrls((prev) => new Set([...prev, item.url]));
          setDebugLoadingState((prev) => ({
            ...prev,
            loadedItems: prev.loadedItems + 1,
            lastLoadedItem: item.url,
          }));
          resolve();
        };

        videoElement.onerror = (error) => {
          console.error(`❌ Error preloading video: ${item.url}`, error);
          setDebugLoadingState((prev) => ({
            ...prev,
            errors: [...prev.errors, { url: item.url, error: error.message }],
          }));
          resolve(); // Resolve anyway to prevent hanging
        };

        // Start preloading
        videoElement.preload = "auto";
        videoElement.src = item.url;
      } else {
        const img = new Image();

        img.onload = () => {
          console.log(`✅ Image preloaded successfully: ${item.url}`);
          setLoadedUrls((prev) => new Set([...prev, item.url]));
          setDebugLoadingState((prev) => ({
            ...prev,
            loadedItems: prev.loadedItems + 1,
            lastLoadedItem: item.url,
          }));
          resolve();
        };

        img.onerror = (error) => {
          console.error(`❌ Error preloading image: ${item.url}`, error);
          setDebugLoadingState((prev) => ({
            ...prev,
            errors: [...prev.errors, { url: item.url, error: error.message }],
          }));
          resolve();
        };

        img.src = item.url;
      }
    });
  };

  const transitionToNext = async () => {
    console.log(
      `🔄 Starting transition from index ${currentIndex} to ${
        (currentIndex + 1) % mediaItems.length
      }`
    );

    clearTimeout(transitionTimer.current);
    clearTimeout(durationTimer.current);

    if (currentMediaRef.current && currentItem.type === "video") {
      console.log(`⏹️ Stopping current video: ${currentItem.url}`);
      currentMediaRef.current.pause();
    }

    if (nextMediaRef.current && nextItem.type === "video") {
      console.log(`▶️ Preparing next video: ${nextItem.url}`);
      nextMediaRef.current.currentTime = 0;
      try {
        await nextMediaRef.current.play();
        console.log(`✅ Next video started playing: ${nextItem.url}`);
      } catch (err) {
        console.error(`❌ Video playback failed: ${nextItem.url}`, err);
      }
    }

    transitionTimer.current = setTimeout(() => {
      console.log(
        `✅ Completing transition to index ${
          (currentIndex + 1) % mediaItems.length
        }`
      );
      setCurrentIndex((prev) => (prev + 1) % mediaItems.length);
    }, transitionDuration);
  };

  const scheduleNextTransition = () => {
    clearTimeout(durationTimer.current);

    if (currentItem.type === "video" && currentMediaRef.current) {
      console.log(`⏱️ Setting up video end transition for: ${currentItem.url}`);
      currentMediaRef.current.onended = transitionToNext;
    } else if (currentItem.type === "image") {
      const durationMs =
        (currentItem.duration.minutes * 60 + currentItem.duration.seconds) *
        1000;
      console.log(
        `⏱️ Scheduling image transition in ${durationMs}ms for: ${currentItem.url}`
      );
      durationTimer.current = setTimeout(transitionToNext, durationMs);
    }
  };

  useEffect(() => {
    const loadAllMedia = async () => {
      console.log(`🚀 Starting to load ${mediaItems.length} media items`);
      setIsLoading(true);

      await Promise.all(mediaItems.map(preloadItem));

      console.log(`✅ Finished loading all media items:`, {
        totalLoaded: loadedUrls.size,
        loadedUrls: Array.from(loadedUrls),
      });

      setIsLoading(false);
    };

    loadAllMedia();

    return () => {
      console.log("🧹 Cleaning up timers and references");
      clearTimeout(transitionTimer.current);
      clearTimeout(durationTimer.current);
    };
  }, [mediaItems]);

  useEffect(() => {
    if (!isLoading) {
      console.log(
        `📊 Loading state changed to ready, scheduling first transition`
      );
      scheduleNextTransition();
    }

    return () => {
      clearTimeout(durationTimer.current);
      if (currentMediaRef.current) {
        currentMediaRef.current.onended = null;
      }
    };
  }, [currentIndex, isLoading]);

  // Debug output effect
  useEffect(() => {
    console.log("📊 Current loading state:", debugLoadingState);
  }, [debugLoadingState]);

  // Rest of the component remains the same...
  const renderMedia = (item, isCurrent) => {
    if (!item || !loadedUrls.has(item.url)) {
      console.log(`⚠️ Attempted to render unloaded media: ${item?.url}`);
      return null;
    }

    const commonStyles = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: isCurrent ? 1 : 0,
      transition: `opacity ${transitionDuration}ms ease-in-out`,
      objectFit: item.fitStyle || defaultFitStyle,
      ...customStyles?.media,
    };

    if (item.type === "video") {
      return (
        <video
          ref={isCurrent ? currentMediaRef : nextMediaRef}
          key={item.url}
          src={item.url}
          style={commonStyles}
          muted
          playsInline
          autoPlay={isCurrent}
          onError={(e) => console.error(`❌ Video playback error:`, e)}
          onPlay={() => console.log(`▶️ Video started playing: ${item.url}`)}
          onEnded={() => console.log(`⏹️ Video ended: ${item.url}`)}
        />
      );
    }

    return (
      <img
        key={item.url}
        src={item.url}
        alt=""
        style={commonStyles}
        onError={(e) => console.error(`❌ Image display error:`, e)}
        onLoad={() => console.log(`✅ Image displayed: ${item.url}`)}
      />
    );
  };

  if (isLoading) {
    return (
      <div
        style={{
          backgroundColor: fallbackColor,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...customStyles?.container,
        }}
      >
        Loading media... ({debugLoadingState.loadedItems}/
        {debugLoadingState.totalItems})
      </div>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: fallbackColor,
        overflow: "hidden",
        ...customStyles?.container,
      }}
    >
      {renderMedia(currentItem, true)}
      {renderMedia(nextItem, false)}
      <div style={{ position: "relative", zIndex: 1 }}>{children}</div>
    </div>
  );
};

export default ScheduledMediaZone;
